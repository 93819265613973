import { css } from "@emotion/react";
import React, { useState } from "react";

import { Box, Button, Card, Grid, Typography } from "@mui/material";

import { useAuth } from "@/custom-hooks/use-auth";
import { useCustomMediaQuery } from "@/custom-hooks/use-custom-media";
import { ELEVATION_APP } from "@/constants/app-config";
import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";

import PrimaryForm from "@/components/common/text-fields/PrimaryForm";
import SvgSuitUpMainLogo from "@/components/svg/SuitUpMainLogo";
import { useSnackbar } from "notistack";

const LoginContainer: React.FC = () => {
  const { authLoginWithEmail } = useAuth();
  const { isMobile } = useCustomMediaQuery();

  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onClickForLogin = async () => {
    if (!email) {
      return enqueueSnackbar("メールアドレスが未入力です", { variant: "error" });
    }
    if (!password) {
      return enqueueSnackbar("パスワードが未入力です", { variant: "error" });
    }
    return authLoginWithEmail({ email, password });
  };

  return (
    <Grid container justifyContent="center" alignItems="center" css={authStyled}>
      <Grid item xs={12}>
        <Card elevation={ELEVATION_APP} css={containerStyled}>
          <Box py={3} px={isMobile ? 5 : 3}>
            <Grid container justifyContent={"center"}>
              <Grid item pb={1}>
                <SvgSuitUpMainLogo />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} pb={5}>
                <Typography variant="h6" noWrap textAlign="center" css={titleStyled}>
                  {"管理者ダッシュボード"}
                </Typography>
              </Grid>
              <Grid item xs={12} pb={2}>
                <PrimaryForm
                  type={"email"}
                  required={true}
                  label={"admin@suitup.jp"}
                  title={"メールアドレス"}
                  value={email}
                  error={false}
                  onChange={setEmail}
                />
              </Grid>
              <Grid item xs={12} pb={2}>
                <PrimaryForm
                  type={"password"}
                  required={true}
                  title={"パスワード"}
                  value={password}
                  error={false}
                  onChange={setPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" pt={3} pb={2}>
                  <Button fullWidth variant="contained" size={"large"} onClick={onClickForLogin}>
                    {"ログイン"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};
export default LoginContainer;

const authStyled = css`
  z-index: -10;
  background-image: url("/stacked-waves-haikei.svg");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
`;

const containerStyled = css`
  max-width: 472px;
  margin: auto;
  border-radius: 16px;
`;

const titleStyled = css`
  font-family: ${appTypo.font.kintoSans};
  color: ${appColor.AuthText.primary};
`;
