import { NextPage } from "next";
import React from "react";

import LoginContainer from "@/components/page/auth/LoginContainer";

const Index: NextPage = () => {
  return <LoginContainer />;
};

export default Index;

export const getServerSideProps = async () => ({
  props: { noHeader: true },
});
