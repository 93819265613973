import { css } from "@emotion/react";
import React, { Dispatch, SetStateAction } from "react";

import { Box, Stack, TextField } from "@mui/material";

import { appTypo } from "@/styles/app-typography";

type Props = {
  type: "password" | "email" | "number" | "string";
  required: boolean;
  label?: string;
  title: string;
  value: string;
  error: boolean;
  onChange: Dispatch<SetStateAction<string>>;
  helperText?: string;
  disabled?: boolean;
};

const PrimaryForm: React.FC<Props> = (props) => {
  return (
    <Stack spacing={1}>
      <Box css={titleStyled}>{props.title}</Box>
      {props?.type === "password" && (
        <TextField
          margin="normal"
          fullWidth
          name="password"
          error={props.error}
          label={props?.label || undefined}
          value={props.value}
          required={props.required}
          type="password"
          autoComplete="current-password"
          size={"small"}
          helperText={props?.helperText && props.helperText}
          onChange={(e) => props.onChange(e.target.value)}
        />
      )}
      {props?.type === "email" && (
        <TextField
          margin="normal"
          fullWidth
          error={props.error}
          label={props.label}
          value={props.value}
          required={props.required}
          name="email"
          autoComplete="email"
          size={"small"}
          helperText={props?.helperText && props.helperText}
          onChange={(e) => props.onChange(e.target.value)}
        />
      )}
      {props.type === "string" && (
        <TextField
          error={props.error}
          label={props.label}
          value={props.value}
          required={props.required}
          variant="outlined"
          helperText={props?.helperText && props.helperText}
          size={"small"}
          onChange={(e) => props.onChange(e.target.value)}
          disabled={props.disabled}
        />
      )}
    </Stack>
  );
};
export default React.memo(PrimaryForm);

const titleStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
`;
