import * as React from "react";
import { SVGProps } from "react";

const SvgSuitUpMainLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={150} height={43} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.634 21.314a7.417 7.417 0 0 1 5.747-7.225 11.133 11.133 0 0 0 8.892 8.892 7.414 7.414 0 0 1-14.64-1.666ZM28.54 10.381a11.133 11.133 0 0 0-8.892 8.892 7.414 7.414 0 1 1 8.892-8.892Zm13.162 10.933c0-5.556-4.075-10.16-9.4-10.988-.827-5.324-5.431-9.4-10.988-9.4-5.556 0-10.16 4.076-10.988 9.4-5.324.828-9.4 5.432-9.4 10.988 0 5.556 4.076 10.161 9.4 10.989.828 5.324 5.432 9.4 10.988 9.4 5.556 0 10.161-4.076 10.989-9.4 5.324-.828 9.4-5.432 9.4-10.989Zm-9.454 7.226a7.414 7.414 0 1 0-8.892-8.892 11.132 11.132 0 0 1 8.892 8.892Zm-9.267-5.184a11.132 11.132 0 0 1-8.892 8.892 7.414 7.414 0 1 0 8.892-8.892Z"
      fill={"#34387C"}
    />
    <path
      d="M53.286 25.25c0 3.934 2.788 6.722 7.378 6.722 4.558 0 7.542-2.951 7.542-5.903 0-7.509-10.657-5.607-10.657-9.181 0-1.148 1.148-2.296 3.115-2.296 1.968 0 3.115 1.115 3.115 2.952h3.771c0-3.771-2.787-6.559-6.886-6.559-4.427 0-7.214 2.788-7.214 5.936 0 7.18 10.657 5.213 10.657 8.984 0 1.312-1.115 2.46-3.443 2.46-2.131 0-3.443-1.279-3.443-3.115h-3.935ZM70.978 25.577c0 3.935 2.492 6.395 6.066 6.395 1.214 0 2.296-.394 3.05-.82.426-.23.853-.525 1.213-.82l.656 1.476h2.787V16.724h-3.77v8.361c0 1.968-1.28 3.28-3.116 3.28s-3.115-1.312-3.115-3.28v-8.361h-3.771v8.853ZM88.367 31.808h3.77V16.724h-3.77v15.084Zm1.967-16.888c1.148 0 2.132-.984 2.132-2.131 0-1.148-.984-2.132-2.132-2.132-1.148 0-2.131.984-2.131 2.132 0 1.147.983 2.131 2.131 2.131ZM96.88 26.561c0 3.28 2.098 5.41 5.082 5.41.492 0 .918-.065 1.279-.163a2.43 2.43 0 0 0 .524-.164v-3.28l-.262.1a3.33 3.33 0 0 1-.721.065c-1.312 0-2.132-.82-2.132-2.132v-6.23h3.28v-3.443h-3.28v-3.607h-3.279v2.131c0 1.213-.59 1.804-1.803 1.804h-.984v3.115h2.295v6.394ZM114.565 23.938c0 4.59 3.443 8.033 8.198 8.033 4.754 0 8.197-3.443 8.197-8.033v-12.79h-3.934v12.79c0 2.623-1.64 4.262-4.263 4.262-2.624 0-4.263-1.64-4.263-4.262v-12.79h-3.935v12.79ZM135.063 31.808h3.935V24.92h3.771c3.935 0 7.05-3.082 7.05-6.886 0-3.804-3.115-6.886-7.05-6.886h-7.706v20.659Zm3.935-10.658v-6.23h3.771c1.64 0 3.115 1.475 3.115 3.115 0 1.64-1.475 3.115-3.115 3.115h-3.771Z"
      fill={"#34387C"}
    />
  </svg>
);

export default React.memo(SvgSuitUpMainLogo);
